export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const LOGIN_OUT_USER = 'LOGIN_OUT_USER';
export const LOGIN_OUT_USER_SUCCESS = 'LOGIN_OUT_USER_SUCCESS';
export const LOGIN_OUT_USER_ERROR = 'LOGIN_OUT_USER_ERROR';


export const USER_SEARCH = 'USER_SEARCH';
export const USER_SEARCH_SUCCESS = 'USER_SEARCH_SUCCESS';
export const USER_SEARCH_FAILED = 'USER_SEARCH_FAILED';
export const USER_SEARCH_ERROR = 'USER_SEARCH_ERROR';
export const USER_SEARCH_RESTRICT = 'USER_SEARCH_RESTRICT';

export const USER_SEARCH_COUNTER = 'USER_SEARCH_COUNTER';

export const USER_SEARCH_COUNTER_REFRESH = 'USER_SEARCH_COUNTER_REFRESH';