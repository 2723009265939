

import React from 'react';
 
class Footer extends React.Component {
 
    render() {
        return (
            <div>
               <h1> </h1>
            </div>
        )
    }
}
 
export default Footer;
